var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "exporters-wrapper" },
    [
      _vm.isMobile
        ? _c("ControlTableExportersMobile", {
            attrs: { "table-data": _vm.preparedTableData },
            on: {
              editRow: _vm.handleEdit,
              deleteRow: _vm.handleDelete,
              selectDropdownMenu: _vm.handleTableMenu,
              selectionChange: _vm.handleSelectionChange,
            },
          })
        : _c(
            "div",
            { staticClass: "control-table control-table-exporters" },
            [
              _vm.preparedTableData.length || _vm.search
                ? _c(
                    "div",
                    { staticClass: "container container--full-screen" },
                    [
                      _c("TableHeader", {
                        attrs: {
                          search: _vm.search,
                          "search-placeholder": "Поиск по названию",
                          "search-width": "330px",
                        },
                        on: {
                          input: _vm.handleFilterInput,
                          clear: _vm.handleClearFilter,
                          search: _vm.handleSearchFilter,
                        },
                      }),
                      _vm.preparedTableData.length
                        ? _c("TableBody", {
                            attrs: {
                              "custom-class": "exporters",
                              "table-data": _vm.preparedTableData,
                              "table-fields-desktop":
                                _vm.controlTableExporterFieldsDesktop,
                              "table-dropdown-menu-list":
                                _vm.controlTableExporterDropdownMenuList,
                              "is-loading": _vm.getIsLoading,
                              "has-icons": !_vm.isViewer,
                            },
                            on: {
                              editRow: _vm.handleEdit,
                              deleteRow: _vm.handleDelete,
                              selectDropdownMenu: _vm.handleTableMenu,
                              selectionChange: _vm.handleSelectionChange,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (slotProps) {
                                    return [
                                      _c("TableCell", {
                                        attrs: {
                                          row: slotProps.data.row,
                                          item: slotProps.data.row[
                                            slotProps.data.prop
                                          ],
                                          "prop-key": slotProps.data.prop,
                                          status: slotProps.data.row.status,
                                          width: slotProps.data.width,
                                          "min-width": slotProps.data.minWidth,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1404414410
                            ),
                          })
                        : _c("div", { staticClass: "no-filtered" }, [
                            _vm._v(
                              " В списке нет экспортеров, соответствующих указанному фильтру "
                            ),
                          ]),
                      _c("TableFooter", {
                        attrs: {
                          pagination: _vm.pagination,
                          "text-quantity": "Всего экспортеров:",
                          "item-names": "экспортеров",
                        },
                        on: { page: _vm.changePage, size: _vm.changeSize },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.preparedTableData.length && !_vm.search && !_vm.getIsLoading
                ? _c("TableEmpty", {
                    attrs: {
                      warning: "У вас не создано ни одного экспортера",
                      proposition: "Добавить экспортера",
                    },
                    on: { add: _vm.addNewExporter },
                  })
                : _vm._e(),
            ],
            1
          ),
      _c("DialogEditExporter", { attrs: { params: _vm.params } }),
      _c("DialogRemoveTableRow", { attrs: { params: _vm.params } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }