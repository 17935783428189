import {
  controlTableAddItem,
  controlTableRemoveSelectedItems,
} from '@/views/control/data/control'

export const controlTableExporterFieldsDesktop = [
  { prop: 'name', label: 'Экспортер' },
  { prop: 'inn', label: 'ИНН экспортера', width: '200px', align: 'center' },
  { prop: 'fio', label: 'ФИО', width: '300px', align: 'center' },
  { prop: 'phones', label: 'Телефон', width: '200px', align: 'center' },
]

export const controlTableExporterDropdownMenuList = [
  {
    id: 1,
    label: 'Удалить выбранные',
    value: controlTableRemoveSelectedItems,
    class: 'm-b-12',
  },
  {
    id: 2,
    label: 'Добавить экспортера',
    value: controlTableAddItem,
    isDivided: true,
  },
]
