<template>
  <div class="exporters-wrapper">
    <ControlTableExportersMobile
      v-if="isMobile"
      :table-data="preparedTableData"
      @editRow="handleEdit"
      @deleteRow="handleDelete"
      @selectDropdownMenu="handleTableMenu"
      @selectionChange="handleSelectionChange"
    />

    <div v-else class="control-table control-table-exporters">
      <div
        v-if="preparedTableData.length || search"
        class="container container--full-screen"
      >
        <TableHeader
          :search="search"
          search-placeholder="Поиск по названию"
          search-width="330px"
          @input="handleFilterInput"
          @clear="handleClearFilter"
          @search="handleSearchFilter"
        />
        <TableBody
          v-if="preparedTableData.length"
          custom-class="exporters"
          :table-data="preparedTableData"
          :table-fields-desktop="controlTableExporterFieldsDesktop"
          :table-dropdown-menu-list="controlTableExporterDropdownMenuList"
          :is-loading="getIsLoading"
          :has-icons="!isViewer"
          @editRow="handleEdit"
          @deleteRow="handleDelete"
          @selectDropdownMenu="handleTableMenu"
          @selectionChange="handleSelectionChange"
        >
          <template #default="slotProps">
            <TableCell
              :row="slotProps.data.row"
              :item="slotProps.data.row[slotProps.data.prop]"
              :prop-key="slotProps.data.prop"
              :status="slotProps.data.row.status"
              :width="slotProps.data.width"
              :min-width="slotProps.data.minWidth"
            />
          </template>
        </TableBody>

        <div v-else class="no-filtered">
          В списке нет экспортеров, соответствующих указанному фильтру
        </div>

        <TableFooter
          :pagination="pagination"
          text-quantity="Всего экспортеров:"
          item-names="экспортеров"
          @page="changePage"
          @size="changeSize"
        />
      </div>
      <TableEmpty
        v-if="!preparedTableData.length && !search && !getIsLoading"
        warning="У вас не создано ни одного экспортера"
        proposition="Добавить экспортера"
        @add="addNewExporter"
      />
    </div>

    <DialogEditExporter :params="params" />
    <DialogRemoveTableRow :params="params" />
  </div>
</template>

<script>
import {
  CONTROL_TABLE_EXPORTER_EDIT_ROW,
  CONTROL_TABLE_EXPORTER_REMOVE_ROW,
} from '@/constants/dialogs'
import {
  FETCH_EXPORTERS_PAGE,
  GET_EXPORTERS_FOR_TABLE,
  GET_EXPORTERS_PAGINATION,
  GET_IS_LOADING_EXPORTERS,
} from '@/views/control/store/actions'
import { GET_IS_MOBILE, GET_TERMINAL_CURRENT_ID } from '@/store/actions'
import { TableBody, TableEmpty, TableFooter, TableHeader } from '@/UI/table'
import {
  controlTableAddItem,
  controlTableRemoveSelectedItems,
} from '@/views/control/data/control'
import {
  controlTableExporterDropdownMenuList,
  controlTableExporterFieldsDesktop,
} from './data/ControlTableExporters'
import { mapActions, mapGetters } from 'vuex'
import { phoneRegex } from '@/core/helpers'
import ControlTableExportersMobile from './ControlTableExportersMobile'
import DialogEditExporter from './components/dialogs/dialog-edit-exporter/DialogEditExporter'
import DialogRemoveTableRow from './components/dialogs/dialog-remove-table-row/DialogRemoveTableRow'
import TableCell from './components/TableCell'

export default {
  name: 'ControlTableExporters',
  components: {
    DialogEditExporter,
    DialogRemoveTableRow,
    TableFooter,
    TableBody,
    TableHeader,
    TableCell,
    TableEmpty,
    ControlTableExportersMobile,
  },
  data() {
    return {
      controlTableExporterFieldsDesktop,
      controlTableExporterDropdownMenuList,
      multipleSelectionIds: [],
      multipleSelectionNames: [],
      page: 1,
      perPage: 10,
      search: '',
    }
  },
  computed: {
    ...mapGetters({
      currentTerminal: GET_TERMINAL_CURRENT_ID,
      tableData: GET_EXPORTERS_FOR_TABLE,
      pagination: GET_EXPORTERS_PAGINATION,
      getIsLoading: GET_IS_LOADING_EXPORTERS,
      isMobile: GET_IS_MOBILE,
    }),
    // предварительно преобразуем телефоны экспортеров из формата БД в читаемый
    preparedTableData() {
      return this.tableData.map(item => {
        let newExporter = {}

        for (let key in item) {
          if (key !== 'users') {
            newExporter[key] = item[key]
          } else {
            newExporter[key] = item[key].map(user => {
              let formattedPhone = {}

              for (let field in user) {
                formattedPhone[field] =
                  field === 'phone' ? phoneRegex(user[field]) : user[field]
              }

              return formattedPhone
            })
          }
        }

        return newExporter
      })
    },
    params() {
      return { search: this.search, page: this.page, 'per-page': this.perPage }
    },
  },
  watch: {
    currentTerminal() {
      setTimeout(() => this.fetchExporters(), 500)
    },
  },
  mounted() {
    this.fetchExporters()
  },
  methods: {
    ...mapActions({ exporterFetch: FETCH_EXPORTERS_PAGE }),
    fetchExporters() {
      this.exporterFetch(this.params)
    },
    handleFilterInput(value) {
      this.search = value
    },
    handleClearFilter() {
      this.search = ''
      this.handleSearchFilter()
    },
    handleSearchFilter() {
      this.fetchExporters()
    },
    changePage(page = 1) {
      this.page = page
      this.fetchExporters()
    },
    changeSize(size = 10) {
      this.perPage = size
      this.fetchExporters()
    },
    handleEdit(row) {
      this.setDialog({
        name: CONTROL_TABLE_EXPORTER_EDIT_ROW,
        visible: true,
        data: row,
      })
    },
    handleDelete({ id, name }) {
      this.setDialog({
        name: CONTROL_TABLE_EXPORTER_REMOVE_ROW,
        visible: true,
        data: {
          isMultipleDelete: false,
          id,
          name,
        },
      })
    },
    handleTableMenu(command) {
      if (command === controlTableRemoveSelectedItems) {
        this.setDialog({
          name: CONTROL_TABLE_EXPORTER_REMOVE_ROW,
          visible: true,
          data: {
            isMultipleDelete: true,
            idsList: this.multipleSelectionIds,
            namesList: this.multipleSelectionNames,
          },
        })
      }
      if (command === controlTableAddItem) {
        this.addNewExporter()
      }
    },
    handleSelectionChange({ ids, local_names }) {
      this.multipleSelectionIds = ids
      this.multipleSelectionNames = local_names
    },
    addNewExporter() {
      this.setDialog({
        name: CONTROL_TABLE_EXPORTER_EDIT_ROW,
        visible: true,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.control-table-exporters
  padding-top: 20px
  margin-top: 20px
.no-filtered
  display: flex
  justify-content: center
  align-items: center
  font-size: 24px
  height: 200px
  color: $main-font
</style>
